import React from "react";
import QRCode from "qrcode.react";
import "./QRCodeGenerator.css";
// import defaultUserImg from "../assets/static/defaultuser.png";

const QRCodeGenerator = ({ avatar, website }) => {
  // Customize the data and logo as per your requirements
  const qrCodeData = "https://" + website;
  const qrCodeLogo = avatar; // Path to your logo image

  return (
    <div className="qrcode-container">
      <QRCode
        value={qrCodeData}
        size={200} // Adjust the size of the QR code as needed
        fgColor="#000"
        bgColor="transparent"
        imageSettings={{
          src: qrCodeLogo,
          excavate: true,
          height: 40, // Adjust the logo height as needed
          width: 40, // Adjust the logo width as needed
        }}
      />
    </div>
  );
};

export default QRCodeGenerator;
