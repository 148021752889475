import React from "react";
import "./style.css";

const Loader = ({ loaderImg }) => {
  return (
    <div>
      <div className="loader">
        <img src={loaderImg} alt="" width={100} />
      </div>
      <div className="loader-overlay"></div>
    </div>
  );
};

export default Loader;
