import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

import { useTranslation } from "react-i18next";

// style
import "./style.css";
import "../../fonts/font.css";

// images
import Banner from "../../assets/static/banner.png";
import qrcodeIcon from "../../assets/static/qrcode-icon.svg";
import shareIcon from "../../assets/static/sharecircle-icon.svg";
import trophyIcon from "../../assets/static/trophy-icon.svg";

const Home = () => {
  const { t } = useTranslation();

  const location = useLocation();

  useEffect(() => {
    const scrollToPricing = () => {
      const pricingSection = document.getElementById("pricing");
      if (pricingSection) {
        pricingSection.scrollIntoView({ behavior: "smooth" });
      }
    };

    if (location.hash === "#pricing") {
      scrollToPricing();
    }
  }, [location.hash]);

  return (
    <main className="main">
      <section className="about">
        <div className="about__container qr__container">
          <div className="about__info">
            <h1 className="about__title">{t("aboutTitle")}</h1>
            <p className="about__description">{t("aboutDescription")}</p>
            <Link
              to="https://t.me/codecraftersuz_bot"
              className="about-createBtn"
              style={{ textTransform: "uppercase" }}
            >
              {t("createVcard")}
            </Link>
          </div>
          <img
            src={Banner}
            alt=""
            width={468}
            height={534}
            className="about__banner"
          />
        </div>
      </section>
      <section className="work">
        <div className="work__container qr__container">
          <h3 className="work__title">{t("workTitle")}</h3>
          <ul className="work__list">
            <li className="work__item">
              <img
                src={qrcodeIcon}
                alt=""
                className="work__item-img"
                width={72}
              />
              <h3 className="work__item-title">{t("workItemTitle1")}</h3>
              <p className="work__item-description">
                {t("workItemDescription1")}
              </p>
            </li>
            <li className="work__item">
              <img
                src={shareIcon}
                alt=""
                className="work__item-img"
                width={72}
              />
              <h3 className="work__item-title">{t("workItemTitle2")}</h3>
              <p className="work__item-description">
                {t("workItemDescription2")}
              </p>
            </li>
            <li className="work__item">
              <img
                src={trophyIcon}
                alt=""
                className="work__item-img"
                width={72}
              />
              <h3 className="work__item-title">{t("workItemTitle3")}</h3>
              <p className="work__item-description">
                {t("workItemDescription3")}
              </p>
            </li>
          </ul>
        </div>
      </section>
      <section id="pricing">
        <div className="pricing__container qr__container">
          <h1 className="pricing__title">{t("prices")}</h1>
          <ul className="pricing__list">
            <li className="pricing__item">
              <div className="pricing__item-title">{t("freePrice")}</div>
              <div className="pricing__item-content">
                <h2 className="pricing__price">
                  0 {t("uzs")} <span>/15 {t("days")}</span>
                </h2>
                <Link to="https://t.me/codecraftersuz_bot" className="pricing__btn">
                  {t("freeTrial")}
                </Link>
              </div>
            </li>
            <li className="pricing__item">
              <div className="pricing__item-title">{t("monthly")}</div>
              <div className="pricing__item-content">
                <h2 className="pricing__price">
                  15 000 {t("uzs")} <span>/{t("month")}</span>
                </h2>
                <Link to="https://t.me/codecraftersuz_bot" className="pricing__btn">
                  {t("buy")}
                </Link>
              </div>
            </li>
            <li className="pricing__item">
              <div className="pricing__item-title">{t("annually")}</div>
              <div className="pricing__item-content">
                <h2 className="pricing__price">
                  150 000 {t("uzs")} <span>/{t("year")}</span>
                </h2>
                <Link to="https://t.me/codecraftersuz_bot" className="pricing__btn">
                  {t("buy")}
                </Link>
              </div>
            </li>
          </ul>
        </div>
      </section>
    </main>
  );
};

export default Home;
