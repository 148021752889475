import React from "react";
import "./style.css";
import "../../fonts/font.css";

// images
// import logoWhite from "../../assets/static/logo-white.png";

const Footer = () => {
  return (
    <footer>
      <div className="footer__container qr__container">
        <p id="contact">
          {" "}
          {
            //   <a href="tel:+998970403121">+998(97)040-31-21</a>{" "}
          }
        </p>
        <p>Copyright ©️ CodeCrafters 2023</p>
      </div>
    </footer>
  );
};

export default Footer;
