import React, { useState, useEffect, useContext, useCallback } from "react";
import "./style.css";
import logo from "../../assets/static/logo.png";
import { Link, useLocation } from "react-router-dom";
import { FiLogIn } from "react-icons/fi";
import { GrAddCircle } from "react-icons/gr";
import { GiHamburgerMenu } from "react-icons/gi";
import { useTranslation } from "react-i18next";
import { HOME_ROUTE, USERDASHBOARD_ROUTE } from "../../utils/consts";
import { Context } from "../..";
import { authCheck } from "../../mobx/Actions/authActions";

const Header = () => {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const location = useLocation();
  const { user } = useContext(Context);

  const logOut = () => {
    localStorage.clear();
    user.setIsAuth(false);
  };

  const checkIsAuth = useCallback(async () => {
    await authCheck().then((res) => {
      if (res === true) {
        user.setIsAuth(true);
      } else {
        user.setIsAuth(false);
      }
    });
  }, [user]);

  useEffect(() => {
    checkIsAuth();
  });

  const [showNav, setShowNav] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  const handleBurger = () => {
    !showNav ? setShowNav(true) : setShowNav(false);
  };

  const langItem = localStorage.key("lang")
    ? localStorage.getItem("lang")
    : "uz";

  const [translate, setTranslate] = useState(langItem);
  const [navbarTranslate, setNavbarTranslate] = useState(false);

  const changeLanguage = (language) => {
    localStorage.setItem("lang", language);
    const localLang = localStorage.getItem("lang");
    setTranslate(localLang);
    i18n.changeLanguage(localLang);
    setNavbarTranslate(false);
  };

  const showNavTranslate = () => {
    !navbarTranslate ? setNavbarTranslate(true) : setNavbarTranslate(false);
  };

  useEffect(() => {
    const storedLanguage = localStorage.getItem("lang");
    if (!storedLanguage) {
      const defaultLanguage = "uz"; // Replace "uz" with the desired default language key
      localStorage.setItem("lang", defaultLanguage);
      setTranslate(defaultLanguage);
      i18n.changeLanguage(defaultLanguage);
    } else {
      setTranslate(storedLanguage);
      i18n.changeLanguage(storedLanguage);
    }
  }, [i18n]);

  useEffect(() => {
    const handleScroll = () => {
      // Check if the user has scrolled beyond a specific threshold (e.g., 100px)
      const isScrolled = window.scrollY > 100;
      setScrolled(isScrolled);
    };

    // Add the scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const isVcardPage = location.pathname.includes("/vcard/");

  return (
    <header className={scrolled ? "scrolled-header" : ""}>
      <div className="header__container qr__container">
        <GiHamburgerMenu onClick={() => handleBurger()} className="burger" />
        <Link to={user.isAuth === false ? HOME_ROUTE + "#": USERDASHBOARD_ROUTE}>
          <img src={logo} alt="QR Vizitka logo" width={60} />
        </Link>
        <nav className={`navbar-mobile ${showNav && "show-nav"}`}>
          <ul className="navbar__list">
            <li className="navbar__item">
              <Link to="/prices" className="navbar__link">
                {t("prices")}
              </Link>
            </li>
            <li className="navbar__item">
              <Link to="#contact" className="navbar__link">
                {t("contact")}
              </Link>
            </li>

            <li className="navbar__item">
              <Link
                to="https://t.me/codecraftersuz_bot"
                className="navbar__link nav-create"
              >
                <span>{t("createVcard")}</span>
                <GrAddCircle className="nav-add" />
              </Link>
            </li>
            {!isVcardPage && (
              <li className="navbar__item navbar__item-trans">
                <span onClick={showNavTranslate}>{translate}</span>
                {navbarTranslate && (
                  <ul className="navbar__translate">
                    <li
                      onClick={() => changeLanguage("uz")}
                      className="navbar__translate-item"
                    >
                      uz
                    </li>
                    <li
                      onClick={() => changeLanguage("ru")}
                      className="navbar__translate-item"
                    >
                      ru
                    </li>
                  </ul>
                )}
              </li>
            )}
          </ul>
        </nav>
        <nav className="navbar-desktop">
          <ul className="navbar__list">
            <li className="navbar__item">
              <Link to={HOME_ROUTE + "#pricing"} className="navbar__link">
                {t("prices")}
              </Link>
            </li>
            <li className="navbar__item">
                <Link to="https://t.me/codecraftersuz_bot" className="navbar__link">
                {t("contact")}
              </Link>
            </li>

            <li className="navbar__item">
              <Link
                to="https://t.me/codecraftersuz_bot"
                className="navbar__link nav-create"
              >
                <span className="nav-create-text1">{t("createVcard")}</span>
                <span className="nav-create-text2">{t('createVcard')}</span>
                <GrAddCircle className="nav-add" />
              </Link>
            </li>
            {/*  user.isAuth === false && (
              <li className="navbar__item">
                <Link to="/auth" className="navbar__link navauth">
                  <span className="navbar__link-text ">{t("auth")}</span>
                  <FiLogIn className="navbar__link-icon" />
                </Link>
              </li>
            )*/}
            {user.isAuth === true && (
              <li className="navbar__item">
                <Link to={HOME_ROUTE}>
                  <button onClick={logOut} className="navbar__link navauth">
                    <span className="navbar__link-text ">{t("logout")}</span>
                    <FiLogIn className="navbar__link-icon" />
                  </button>
                </Link>
              </li>
            )}
            {!isVcardPage && (
              <li className="navbar__item navbar__item-trans">
                <span onClick={showNavTranslate}>{translate}</span>
                {navbarTranslate && (
                  <ul className="navbar__translate">
                    <li
                      onClick={() => changeLanguage("uz")}
                      className="navbar__translate-item"
                    >
                      uz
                    </li>
                    <li
                      onClick={() => changeLanguage("ru")}
                      className="navbar__translate-item"
                    >
                      ru
                    </li>
                  </ul>
                )}
              </li>
            )}
          </ul>
        </nav>

        <Link to="/auth" className="navbar__link navauth navauth-btn">
          <span className="navbar__link-text ">{t("auth")}</span>
          <FiLogIn className="navbar__link-icon" />
        </Link>
      </div>
    </header>
  );
};

export default Header;
