/* eslint-disable import/no-anonymous-default-export */
import axios from "axios";

export const userAuth = async (payload) => {
  return await axios.post(
    `${process.env.REACT_APP_API_URL}/api/user/auth`,
    payload
  );
};

export const confirmCode = async (payload) => {
  return await axios.post(
    `${process.env.REACT_APP_API_URL}/api/user/confirm`,
    payload
  );
};

export const checkAuth = async (accessToken) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/user/check`,
      {
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    // Обработка ошибки, если что-то пошло не так
    throw error; // Чтобы сообщить вызывающей стороне о возникшей ошибке
  }
};
