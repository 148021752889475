import Loader from "./components/Loader";
// import Auth from "./screens/Auth";
import Home from "./screens/Home";
import Vcard from "./screens/Vcard";
import CreateVcard from "./screens/CreateVcard";
// import UserDashboard from "./screens/UserDashboard";
import {
//   AUTH_ROUTE,
  HOME_ROUTE,
  LOADER_ROUTE,
//   QR_MENU_ROUTE,
//   USERDASHBOARD_ROUTE,
  VCARD_ROUTE,
} from "./utils/consts";
// import QrMenu from "./screens/QrPrices";

export const authRoutes = [];

export const routes = [
  {
    path: VCARD_ROUTE,
    Component: CreateVcard,
  },
//   {
//     path: USERDASHBOARD_ROUTE,
//     Component: UserDashboard,
//   },
  {
    path: HOME_ROUTE,
    Component: Home,
  },
  {
    path: VCARD_ROUTE + "/:id",
    Component: Vcard,
  },
//   {
//     path: AUTH_ROUTE,
//     Component: Auth,
//   },
  {
    path: LOADER_ROUTE,
    Component: Loader,
  },
//   {
//     path: QR_MENU_ROUTE,
//     Component: QrMenu,
//   },
];
