import React, { Fragment } from "react";
import { Routes, Route } from "react-router-dom";
// import NotFound from "./../screens/NotFound/index";
import Header from "../components/Header";
import { routes } from "../routes";
// import { Context } from "..";
import Footer from "../components/Footer";
import Home from "../screens/Home";
// import { authCheck } from "../mobx/Actions/authActions";

const Routers = () => {
  // const { user } = useContext(Context);

  // const isAuth = false;

  return (
    <Fragment>
      <Header />
      <Routes>
        {/* Public routes */}
        {routes.map(({ path, Component }) => (
          <Route key={path} path={path} element={<Component />} />
        ))}

        {<Route path="*" element={<Home />} />}
      </Routes>
      {
        <Footer />
      }
    </Fragment>
  );
};

export default Routers;
