export const authReducer = (data) => {
  localStorage.setItem("token", JSON.stringify(data.token));
  localStorage.setItem("user_id", JSON.stringify(data.user_id));
};

export const phoneReducer = (phone) => {
  localStorage.setItem("phone", JSON.stringify(phone));
};

export const checkReducer = (data) => {
  localStorage.setItem("token", JSON.stringify(data));
};
