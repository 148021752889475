import { userAuth, confirmCode, checkAuth } from "../../api/auth";
import {
  authReducer,
  checkReducer,
  phoneReducer,
} from "../Reducers/AuthReducer";

export const authUser = async (data) => {
  console.log(data.phone);
  try {
    const res = await userAuth(data);
    phoneReducer(data.phone);
    if (res.status === 200 || res.status === 201) {
      return res.data;
    }
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const confirmUser = async (data) => {
  const getConfirm = {
    phone: JSON.parse(localStorage.getItem("phone")),
    code: data,
  };
  console.log(getConfirm);
  try {
    const res = await confirmCode(getConfirm);
    if (res.status === 200) {
      authReducer(res.data);
      return true;
    }
    console.log(res.data);
  } catch (err) {
    console.log(err);
    return false;
  }
};

export const authCheck = async () => {
  try {
    const getToken = await localStorage.getItem("token");
    if (null !== getToken || undefined !== getToken || getToken.length > 0) {
      const token = JSON.parse(getToken);
      await checkAuth(token).then((response) => {
        checkReducer(response.token);
      });
      return true;
    }
    return false;
  } catch (err) {
    // localStorage.clear()
    // console.log(err);
    return err;
  }
};
