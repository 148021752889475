import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const lng = localStorage.getItem("lang") || "uz";

i18n.use(initReactI18next).init({
  resources: {
    ru: {
      translation: {
        info: "vCard QR Code",
        nameInput: "Имя",
        contact: "Контакт",
        workPhoneNum: "Мобильный",
        phoneNum: "Телефон",
        company: "Компания",
        companyName: "Название компании",
        job: "Позиция",
        website: "Веб-сайт",
        socials: "Социальные сети",
        instaTitle: "Заголовок в инстаграме",
        telegramTitle: "Заголовок в телеграм",
        photoInput: "Фотография",
        generateQrCode: "СОЗДАТЬ QR-КОД",
        call: "ВЫЗОВ",
        socialPages: "Страницы в соц сетях",
        callWhatsapp: "Звонить через Whatsapp",
        auth: "Авторизация",
        logout: "Выход",
        enterPhoneNum: "Пожалуйста, введите свой номер телефона",
        enterSendCode: "Пожалуйста, введите присланный код",
        sendCode: "Отправить код",
        aboutTitle: "Все начинается с цифровой визитки.",
        aboutDescription:
          "Простой способ поделиться контактами (мессенджеры, телефон, е-mail и другие) с клиентами и партнерами",
        createVcard: "Создать Визитку",
        workTitle: "Как это устроено?",
        workItemTitle1: "Создать цифровую визитку",
        workItemDescription1:
          "С легкостью создайте QR-код для своей визитной карточки и произведите отличное первое впечатление. Заполните свой профиль, это просто",
        workItemTitle2: "Поделитесь своей картой с кем угодно",
        workItemDescription2:
          "С легкостью создайте QR-код для своей визитной карточки и произведите отличное первое впечатление. Заполните свой профиль, это просто",
        workItemTitle3: "Получите больше клиентов",
        workItemDescription3:
          "Ваши клиенты найдут способ связаться с вами. Все, что им нужно сделать, это отсканировать QR-код и выбрать лучший канал для связи с вами.",
        prices: "Тарифы",
        freePrice: "Пробный",
        days: "дней",
        freeTrial: "Попробовать",
        monthly: "Ежемесячно",
        month: "месяц",
        buy: "Купить",
        annually: "Eжегодно",
        year: "год",
        uzs: "сум",
        chooseFile: "Выберите файл",
      },
    },
    uz: {
      translation: {
        info: "vCard QR kodi",
        nameInput: "Ism",
        contact: "Bog'lanish",
        workPhoneNum: "Mobil raqam",
        phoneNum: "Telefon raqam",
        company: "Kompaniya",
        companyName: "Kompaniya nomi",
        job: "Lavozim",
        website: "Veb-sayt",
        socials: "Ijtimoiy tarmoqlar",
        instaTitle: "Instagram sarlavhasi",
        telegramTitle: "Telegram sarlavhasi",
        photoInput: "Fotosurat",
        generateQrCode: "QR KOD YARATISH",
        call: "QO'NG'IROQ",
        socialPages: "Ijtimoiy tarmoqlardagi sahifalar",
        callWhatsapp: "Whatsapp orqali qo'ng'iroq qiling",
        auth: "Avtorizatsiya",
        logout: "Chiqish",
        enterPhoneNum: "Iltimos, telefon raqamingizni kiriting",
        enterSendCode: "Iltimos, yuborilgan kodni kiriting",
        sendCode: "Kodni yuborish",
        aboutTitle: "Hammasi raqamli biznes kartadan boshlanadi.",
        aboutDescription:
          "Mijozlar va hamkorlar bilan kontaktlarni (messenjerlar, telefonlar, elektron pochta va boshqalar) almashishning oson usuli",
        createVcard: "QR-Vizitka Yaratish",
        workTitle: "Bu qanday ishlaydi?",
        workItemTitle1: "Raqamli tashrif qog'ozini yarating",
        workItemDescription1:
          "Vizitkangiz uchun osongina QR kod yarating va ajoyib birinchi taassurot qoldiring. Profilingizni to'ldiring, bu oson",
        workItemTitle2: "Kartangizni har kimga ulashing",
        workItemDescription2:
          "Raqamli tashrif qogʻozingizni QR koddan foydalangan har bir kishi bilan osongina baham koʻring yoki uni elektron pochta, havola va boshqalar orqali yuboring. QR kodni yuklab olishingiz va varaqalar, axborotnomalar yoki reklama taxtasi kabi istalgan narsani chop etishingiz mumkin.",
        workItemTitle3: "Ko'proq mijozlarni oling",
        workItemDescription3:
          "Sizning mijozlaringiz siz bilan bog'lanish yo'lini topadi. Ular qilishlari kerak bo'lgan narsa QR kodni skanerlash va siz bilan bog'lanish uchun eng yaxshi kanalni tanlashdir.",
        prices: "Tariflar",
        freePrice: "Sinov",
        days: "kun",
        freeTrial: "Sinab Ko'rish",
        monthly: "Oylik",
        month: "oy",
        buy: "Sotib olish",
        annually: "Yillik",
        year: "yil",
        uzs: "so`m",
        chooseFile: "Fayl tanlang",
      },
    },
    // Add more language translations as needed
  },
  lng: lng, // Set the default language
  fallbackLng: lng, // Specify fallback language if translation is missing
  interpolation: {
    escapeValue: false, // React already escapes values, so no need to escape again
  },
});

export default i18n;
